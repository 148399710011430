<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('externalTradeFair.category_wise_stall_payment') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-overlay :show="loading">
            <b-form id="form" @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <b-col sm="4">
                  <ValidationProvider name="Year" vid="year" v-slot="{ errors }">
                    <b-form-group
                      label-for="year">
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }}
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        :options="yearList"
                        v-model="search.year"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="Title" vid="fair_id" v-slot="{ errors }">
                    <b-form-group
                      label-for="fair_id"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_name') }}
                      </template>
                      <b-form-select
                        disabled
                        plain
                        id="fair_id"
                        :options="dhakaFairList"
                        v-model="search.fair_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="Circular type" vid="circular_type" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="circular_type">
                      <template v-slot:label>
                        {{ $t('ditfTradeFairManages.circular_type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="circular_type"
                        :options="circularTypeList"
                        v-model="search.circular_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4" class="mt-3">
                  <b-button type="submit" size="sm" variant="primary"><i class="ri-search-line"></i> {{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </ValidationObserver>
      </template>
    </card>
    <body-card v-if="search_param.circular_type !== 2">
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('externalTradeFair.category_wise_stall_payment') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-col v-if="stallmentPaymentDate">
          <b-alert variant="danger" v-model="showDismissibleAlert" dismissible fade class="alert alert-danger d-flex align-items-center">
            <!-- <div style="font-weight: 800;"> Thanks. For complete counter payment, print the voucher & payment should be completed within For this date CS and GS is <b>{{ fixedPayment }} </b> and other Category <b>{{ stallmentPaymentDate }} </b> at any branch of Sonali Bank else cancel your application.</div> -->
            <div style="font-weight: 800;"> Thanks. For complete counter payment, print the voucher & payment should be completed by <b>{{ fixedPayment }} </b> for CS and GS categories or by <b>{{ stallmentPaymentDate }} </b> for all other categories at any branch of Sonali Bank else cancel your application.</div>
          </b-alert>
        </b-col>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <!-- <pre>{{ allDatas }}</pre> -->
            <slot v-if="allDatas.length">
              <marquee
                behavior="scroll"
                direction="slide"
                onmouseover="this.stop ();"
                onmouseout="this.start ()"
                style="margin-top: 15px !important;"
              >
                <slot v-for="item in allDatas">
                  <span v-if="parseInt(item.is_paid) === 2" :class="colorCheck(item)" class="font-weight-bold" :key="index">
                    ডিআইটিফ {{ $n(item.app_main.year, { useGrouping: false }) }} আপনার প্রতিষ্ঠানের অনুকূলে
                    ({{ $i18n.locale === 'bn' ? item.stalls : item.stallsEn }})
                    বরাদ্দ হয়েছে. সফটওয়্যার থেকে স্টল বরাদ্দপত্র সংগ্রহ করে নিন।
                  </span>
                  <span v-else-if="!calculateDateDifference(item)" :class="colorCheck(item)" class="font-weight-bold" :key="index">
                    ডিআইটিফ {{ $n(item.app_main.year, { useGrouping: false }) }} আপনার প্রতিষ্ঠানের অনুকূলে বরাদ্দকৃত ({{ $i18n.locale === 'bn' ? item.stalls : item.stallsEn }}) নিদিষ্ট সময়ের মধ্যে অর্থ পরিশোধ না করায় ({{ $i18n.locale === 'bn' ? item.stalls : item.stallsEn }}) এর জমাকৃত জামানত স্বয়ংক্রিয়ভাবে বাজেয়াপ্ত হয়েছে।
                  </span>
                  <span v-else :class="colorCheck(item)" class="font-weight-bold" :key="index">
                    ডিআইটিফ {{ $n(item.app_main.year, { useGrouping: false }) }} আপনার প্রতিষ্ঠানের অনুকূলে ({{ $i18n.locale === 'bn' ? item.stalls : item.stallsEn }}) বরাদ্দ হয়েছে।
                    CS ও GS -এর  বরাদ্দ {{ item.app_main.circular.fixed_category_payment_date | dateFormat }} এবং অন্যান্য ক্যাটাগরীর বরাদ্দ {{ item.app_main.circular.stallment_payment_last_date | dateFormat }} তারিখের মধ্যে অর্থ পরিশোধ করা সাপেক্ষে সফটওয়্যার থেকে বরাদ্দপত্র সংগ্রহ করুন অন্যথায় জমাকৃত জামানত স্বয়ংক্রিয়ভাবে বাজেয়াপ্ত হবে।
                  </span>
                </slot>
              </marquee>
            </slot>
            <br/>
            <b-col md="12" class="table-responsive" style="margin-top: 10px">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :tbody-tr-class="rowClass" :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(cat_name_stall_no)="data">
                    {{ getCategory(data.item.stall_cat_id)+ ', ' + $t('ditfConfig.stall_no') + '-' + (data.item.booked_stall_no ? data.item.booked_stall_no : data.item.stall.stall_no) }}
                  </template>
                  <template v-slot:cell(quoted_price)="data">
                    {{ $n(data.item.quoted_price, { minimumFractionDigits: 2 })  }}
                  </template>
                  <template v-slot:cell(security_money)="data">
                    {{ $n(data.item.security_money, { minimumFractionDigits: 2 }) }}
                  </template>
                  <!-- <template v-slot:cell(payable_amount)="data">
                    {{ $n((data.item.quoted_price - data.item.security_money), { minimumFractionDigits: 2 }) }}
                  </template> -->
                  <template v-slot:cell(pay_status)="data">
                    <span class="badge badge-danger" v-if="parseInt(data.item.is_paid) === 0">{{$t('globalTrans.unpaid')}}</span>
                    <span class="badge badge-warning" v-else-if="parseInt(data.item.is_paid) === 1">{{$t('globalTrans.processing_counter')}}</span>
                    <span class="badge badge-success" v-else>{{$t('globalTrans.paid')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <a class="btn btn-info" style="padding-left: 7px;padding-right: 7px;" v-if="data.item.is_paid === 1 && data.item.sonali_transaction_id" target="_blank" :href="voucherDownload(data.item.sonali_transaction_id)">{{ $t('globalTrans.print_voucher') }}</a>
                    <a class="btn btn-info" style="padding-left: 7px;padding-right: 7px;" v-if="data.item.is_paid === 2 && data.item.sonali_transaction_id" target="_blank" :href="voucherDownload2(data.item.sonali_transaction_id)">{{ $t('globalTrans.print_voucher') }}</a>
                    <b-button v-if="data.item.is_paid === 2 && data.item.sonali_transaction_id" @click="pdfExportData(data.item.id)"  class="btn btn-success mt-1 mr-1 mb-1" size="sm" :title="$t('externalTradeFair.allotment_etter')">{{ $t('externalTradeFair.allotment_etter') }}</b-button>
                    <!-- <b-button v-else v-b-modal.modal-payment-details @click="paymentDetails(data.item)" variant=" iq-bg-success" size="sm" class="action-btn edit" :title="$t('globalTrans.payment') + ' ' + $t('globalTrans.receipt')"><i class="ri-booklet-line"></i></b-button> -->
                    <!-- <b-button v-if="data.item.is_paid === 0" v-b-modal.modal-payment @click="payment(data.item)" variant=" iq-bg-success" size="sm" class="action-btn edit blink_me" :title="$t('globalTrans.payment')"><i class="ri-secure-payment-line"></i></b-button> -->
                    <b-button v-if="parseInt(data.item.is_paid) !== 0" v-b-modal.modal-payment-details @click="paymentDetails(data.item)" variant=" iq-bg-success" size="sm" class="action-btn edit" :title="$t('globalTrans.payment') + ' ' + $t('globalTrans.receipt')"><i class="ri-booklet-line"></i></b-button>
                    <slot v-else-if="!calculateDateDifference(data.item)"><span style="cursor:pointer" class="badge badge-danger" :title="$t('externalUser.forfeited_message')">জামানত ফী বাজেয়াপ্ত</span></slot>
                    <b-button v-else v-b-modal.modal-payment @click="payment(data.item)" variant=" iq-bg-success" size="sm" class="action-btn edit blink_me" :title="$t('globalTrans.payment')"><i class="ri-secure-payment-line"></i></b-button>
                  </template>
                  </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <body-card v-if="search_param.circular_type === 2">
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('externalTradeFair.category_wise_stall_payment') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData2" :search="search" :columns="columns2" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns2.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns2.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(cat_name_stall_no)="data">
                    {{ getCategory(data.item.stall_cat_id) }}
                  </template>
                  <template v-slot:cell(quoted_price)="data">
                    {{ $n(data.item.quoted_price, { minimumFractionDigits: 2 })  }}
                  </template>
                  <!-- <template v-slot:cell(payable_amount)="data">
                    {{ $n(data.item.quoted_price) }}
                  </template> -->
                  <template v-slot:cell(pay_status)="data">
                    <span class="badge badge-danger" v-if="parseInt(data.item.is_paid) === 0">{{$t('globalTrans.unpaid')}}</span>
                    <span class="badge badge-warning" v-else-if="parseInt(data.item.is_paid) === 1">{{$t('globalTrans.processing_counter')}}</span>
                    <span class="badge badge-success" v-else>{{$t('globalTrans.paid')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <a class="btn btn-info" style="padding-left: 7px;padding-right: 7px;" v-if="data.item.is_paid === 1 && data.item.sonali_transaction_id" target="_blank" :href="voucherDownload(data.item.sonali_transaction_id)">{{ $t('globalTrans.print_voucher') }}</a>
                    <a class="btn btn-info" style="padding-left: 7px;padding-right: 7px;" v-if="data.item.is_paid === 2 && data.item.sonali_transaction_id" target="_blank" :href="voucherDownload2(data.item.sonali_transaction_id)">{{ $t('globalTrans.print_voucher') }}</a>
                    <b-button  v-if="data.item.is_paid === 0" v-b-modal.modal-payment @click="payment(data.item)" variant=" iq-bg-success" size="sm" class="action-btn edit blink_me" :title="$t('globalTrans.payment')"><i class="ri-secure-payment-line"></i></b-button>
                    <b-button v-else v-b-modal.modal-payment-details @click="paymentDetails(data.item)" variant=" iq-bg-success" size="sm" class="action-btn edit" :title="$t('globalTrans.payment') + ' ' + $t('globalTrans.receipt')"><i class="ri-booklet-line"></i></b-button>
                  </template>
                  </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns2.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-payment" size="lg" :title="$t('globalTrans.payment') + ' ' + $t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Payment :id="detailsItemId"/>
    </b-modal>
    <b-modal id="modal-payment-details" size="lg" hide-footer ok-only ok-variant="danger">
      <template #modal-title>
        {{ $t('globalTrans.payment') + ' ' + $t('globalTrans.receipt') }}
        <b-button variant="primary" @click="pdfExportPayment" class="mr-2 float-right">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <PaymentDetails :id="payDetailsItemId" :appId="appId" ref="details"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { CategoryWiseStallPaymentList, DitfOnlineAppStallPaymentUpdate, DitfAllotmentLetterPdf } from '../../../api/routes'
import Payment from './payment/PaymentModal'
import PaymentDetails from './payment/PaymentDetailsModal'
import ModalBaseMasterList from '@/mixins/list'
import AddressHelper from '@/utils/area-type-address'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Payment, PaymentDetails
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      valid: '',
      sortBy: '',
      cat_name: '',
      showDismissibleAlert: false,
      allDatas: [],
      search: {
        year: 0,
        fair_id: 0,
        circular_type: 1,
        limit: 20
      },
      search_param: {
        year: 0,
        fair_id: 0,
        circular_type: 0,
        limit: 20
      },
      stallmentPaymentDate: null,
      fixedPayment: null,
      dhakaFairList: [],
      detailsItemId: 0,
      payDetailsItemId: 0,
      securityMoney: 0,
      securityMoneyData: 0,
      appId: 0,
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'globalTrans.application_id', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'ditfAppTradeFairManages.stall_category_stall_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '12%' } },
        { labels: 'externalTradeFair.quoted_price', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
        // { labels: 'externalTradeFair.payable_amount', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
        { labels: 'globalTrans.pay_status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } }
      ],
      labelData2: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'globalTrans.application_id', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'ditfAppTradeFairManages.stall_category_stall_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '12%' } },
        { labels: 'externalTradeFair.quoted_price', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
        // { labels: 'externalTradeFair.payable_amount', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
        { labels: 'globalTrans.pay_status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } }
      ],
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    if (this.$route.query.Token && this.$route.query.Mode) {
      if (this.$route.query.Mode === 'counter') {
        this.showDismissibleAlert = true
      }
      this.paymentStatusUpdate()
    } else {
      this.loadData()
    }
    // this.loadData()
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    listData: function () {
      const tmpData = this.$store.state.list
      return JSON.parse(JSON.stringify(tmpData))
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'app_main.app_auto_id' },
          { key: 'cat_name_stall_no' },
          { key: 'quoted_price' },
          // { key: 'payable_amount' },
          { key: 'pay_status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'app_main.app_auto_id' },
          { key: 'cat_name_stall_no' },
          { key: 'quoted_price' },
          // { key: 'payable_amount' },
          { key: 'pay_status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    columns2 () {
      const labelData = this.labelData2
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'app_main.app_auto_id' },
          { key: 'cat_name_stall_no' },
          { key: 'quoted_price' },
          // { key: 'payable_amount' },
          { key: 'pay_status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'app_main.app_auto_id' },
          { key: 'cat_name_stall_no' },
          { key: 'quoted_price' },
          // { key: 'payable_amount' },
          { key: 'pay_status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    stallTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    colorCheck (data) {
      if (data.is_paid === 2) {
        return 'text-success'
      }
      const rowCount = this.calculateDateDifference(data)
      if (!rowCount) {
        return 'text-danger'
      }
      return 'text-warning'
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return ''
      else if (item) {
        const rowCount = this.calculateDateDifference(item)
        if (!rowCount) {
          return 'table-danger'
        }
      }
    },
    calculateDateDifference (data) {
      if (data.is_paid === 2) {
        return true
      }
      if (data.app_main.circular) {
        let paymendDate = data.app_main.circular.stallment_payment_last_date
        if (parseInt(data.stall_cat_id) === 170 || parseInt(data.stall_cat_id) === 171) {
          paymendDate = data.app_main.circular.fixed_category_payment_date
        }
        if (!paymendDate) {
          return true
        }
        if (
          new Date(paymendDate).setHours(0, 0, 0, 0) >=
          new Date().setHours(0, 0, 0, 0)
        ) {
          return true
        }
      }
      return false
      // return true
    },
    voucherDownload (voucher) {
      return 'https://voucher.sblesheba.com:8877/CounterReceipt/' + voucher
    },
    voucherDownload2 (voucher) {
      return 'https://voucher.sblesheba.com:8877/Receipt/' + voucher
    },
    paymentDetails (item) {
      this.payDetailsItemId = item.id
      this.appId = item.app_main_id
    },
    payment (item) {
      this.detailsItemId = item.id
      this.securityMoneyData = this.securityMoney
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getSecurityPrc (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(n))
        if (typeof Obj !== 'undefined') {
          const percentage = Obj.percentage
          return percentage
        } else {
        return 0
        }
    },
    getSecurityMoney (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(this.search.year))
        if (typeof Obj !== 'undefined') {
          const percentage = Obj.percentage
          const percAmount = (n * percentage) / 100
          this.securityMoney = percAmount
          return percAmount
        } else {
        return 0
        }
    },
    dhakaFair () {
      return this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.dhakaFairList.filter(data => data.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async paymentStatusUpdate () {
      if (this.$route.query.Token && this.$route.query.Mode) {
        const queryParams = {
          Token: this.$route.query.Token,
          Mode: this.$route.query.Mode
        }
        await RestApi.getData(internationalTradeFairServiceBaseUrl, DitfOnlineAppStallPaymentUpdate, queryParams).then(response => {
          if (response.success) {
            this.$toast.success({
              title: 'Success',
              message: response.message,
              color: '#D6E09B'
            })
          } else {
            this.showDismissibleAlert = false
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
        })
        this.loadData()
        this.$router.push('/dhaka-trade-fair-panel/category-wise-stall-payment')
      } else {
        this.loadData()
      }
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, CategoryWiseStallPaymentList, params)
      if (result.success) {
        this.search_param.circular_type = parseInt(result.search_data.circular_type)
        this.allDatas = result.allDatas
        this.stallmentPaymentDate = result.stallmentPaymentDate
        this.fixedPayment = result.fixedPayment
        if (this.search_param.circular_type === 1) {
          const listData = result.data.data.map((item, index) => {
            const maxPrice = result.highestQuotedList.find(el => el.stall_cat_id === item.stall_cat_id)
            return Object.assign({}, item, {
              serial: index,
              security_money: typeof maxPrice !== 'undefined' ? this.getSecurityMoney(maxPrice.highest_quoted_price) : '',
              security_prc: this.getSecurityPrc(item.app_main.year)
            })
          })
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        } else {
          const listData = result.data.data.map((item, index) => {
            return Object.assign({}, item, { serial: index })
          })
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        }
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getStallType (id) {
      const catObj = this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.find(el => el.value === parseInt(id))
      if (catObj !== undefined) {
        return this.currentLocale === 'bn' ? catObj.text_bn : catObj.text_en
      }
    },
    getCategory (id) {
        const catObj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(el => parseInt(el.value) === parseInt(id))
        if (typeof catObj !== 'undefined') {
          return this.$i18n.locale === 'bn' ? catObj.text_bn : catObj.text_en
        }
    },
    changeLabel (cType) {
      if (cType === 2) {
        const labelData = this.labelData
        labelData.map((item, index) => {
        })
      }
    },
    pdfExportPayment () {
      this.$refs.details.pdfExport()
    },
    async pdfExportData (pId) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({ request_type: 'pdf', local: 'bn', id: pId }, this.search)
      this.loading = true
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, DitfAllotmentLetterPdf, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      this.loading = false
    }
  }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
<style scoped>
  .modal-title {
    flex-grow: 1 !important;
  }
  .blink_me {
    animation: blinker 2.5s linear infinite;
    color: red;
  }
  @keyframes blinker {
    50% {
      opacity: .09;
      /* background-color: green; */
    }
  }
  .sub_string{
    width: 90px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
</style>
