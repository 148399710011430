<template>
    <b-row>
        <b-col lg="12" sm="12">
            <b-overlay :show="loader">
                <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
                  <template v-slot:projectNameSlot>
                    {{ }}
                  </template>
                  <b-row v-if="detailsData">
                    <b-col>
                        <h6 class="text-center" style="font-weight:bold">{{ getFairName(detailsData.app_main.fair_id) }}</h6>
                        <!-- <div class="text-center">{{ $i18n.locale == 'en' ? fair_info.fiscal_year_en : fair_info.fiscal_year_bn }}</div> -->
                    </b-col>
                  </b-row>
                </list-report-head>
                <b-row>
                    <b-col md="6" class="mb-2 text-center mx-auto">
                        <h5 style="font-weight:bold;background-color:#ddd">{{ $t('globalTrans.payment') + ' ' + $t('globalTrans.receipt') }}</h5>
                    </b-col>
                </b-row>
                <b-row class="mb-3 mx-2 mt-2">
                  <b-col md="3" style="font-weight:bold">{{$t('externalTradeFair.comp_name')}} {{":"}}</b-col>
                  <b-col md="3" class="p-0 text-left">{{ $i18n.locale == 'bn' ? paymentData?.app_main?.comp_name_bn : paymentData?.app_main?.comp_name_en }}</b-col>
                  <b-col md="3" style="font-weight:bold">{{$t('globalTrans.application_id')}} {{":"}}</b-col>
                  <b-col md="3" class="p-0 text-left">{{ paymentData?.app_main?.app_auto_id }}</b-col>
                  <b-col md="3" style="font-weight:bold">{{ $t('globalTrans.transaction_no') }} {{":"}}</b-col>
                  <b-col md="3" class="p-0 text-left"> {{ paymentData?.transaction_no }}</b-col>
                  <b-col md="3" style="font-weight:bold">{{ $t('globalTrans.date') }} {{":"}}</b-col>
                  <b-col md="3" class="p-0 text-left"> {{ dateData(paymentData?.invoice_date) }}</b-col>
                  <b-col md="3" style="font-weight:bold">{{ $t('globalTrans.pay_status') }} {{":"}}</b-col>
                  <b-col md="3" class="p-0 text-left">
                    <span class="badge badge-danger" v-if="paymentData.payment_status === 0">{{$t('globalTrans.unpaid')}}</span>
                    <span class="badge badge-warning" v-if="paymentData.payment_status === 1">{{$t('globalTrans.processing_counter')}}</span>
                    <span class="badge badge-success" v-else>{{$t('globalTrans.paid')}}</span>
                  </b-col>
                  <template v-if="paymentData.payment_type == 1">
                    <b-col md="3" style="font-weight:bold">{{ $t('globalTrans.voucher_no') }} {{":"}}</b-col>
                  <b-col md="3"> {{ paymentData?.voucher_no }}</b-col>
                  <b-col md="3" style="font-weight:bold">{{ $t('sideBar.bank') + ' ' + $t('globalTrans.name')  }} {{":"}}</b-col>
                  <b-col md="3"> {{ getBankName(paymentData?.bank_id) }}</b-col>
                  <b-col md="3" style="font-weight:bold">{{ $t('sideBar.branch') + ' ' + $t('globalTrans.name')  }} {{":"}}</b-col>
                  <!-- <b-col md="3"> {{ getBranchName(paymentData?.branch_id) }}</b-col> -->
                  <b-col md="3"> {{ paymentData?.branch_name }}</b-col>
                  </template>
                </b-row>
                <b-row>
                    <b-col md="6" class="mb-2 text-center mx-auto">
                        <h5  style="font-weight:bold;background-color:#ddd">{{ $t('ditfConfig.stall_info') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-table-simple bordered small>
                        <thead>
                            <tr class="bg-primary text-center text-align-center">
                                <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th style="width:15%" v-if="detailsData.app_main.circular_type == 1">{{ $t('ditfConfig.stall_no') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                                <th style="width:15%" class="text-right">{{ $t('externalTradeFair.quoted_price') }}</th>
                            </tr>
                        </thead>
                        <b-tbody v-if="detailsData">
                        <b-tr class="text-center">
                            <b-td class="align-middle text-center">{{ $i18n.locale == 'en' ? category_info?.text_en : category_info?.text_bn }}</b-td>
                            <b-td class="text-center" v-if="detailsData.app_main.circular_type == 1">{{ detailsData.booked_stall_no ? detailsData.booked_stall_no : detailsData.stall.stall_no }}</b-td>
                            <b-td class="align-middle text-center">{{ $n(category_info?.floor_price, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td class="text-right">{{ $n(detailsData.quoted_price, { minimumFractionDigits: 2 }) }}</b-td>
                            <!-- <b-td class="align-middle text-right" v-if="detailsData.app_main.circular_type == 2">{{ $n(detailsData.quoted_price) }}</b-td> -->
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right" colspan="3" v-if="detailsData.app_main.circular_type == 1">{{ $t('externalTradeFair.vat') + '(' + $n(getVatPrc(detailsData.app_main.year)) + '%)' }}</b-td>
                            <b-td class="text-right" colspan="2" v-if="detailsData.app_main.circular_type == 2">{{ $t('externalTradeFair.vat') + '(' + $n(getVatPrc(detailsData.app_main.year)) + '%)' }}</b-td>
                            <b-td class="text-right">{{ $n(getVatAmount(detailsData.quoted_price), { minimumFractionDigits: 2 }) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right" colspan="3" v-if="detailsData.app_main.circular_type == 1">{{ $t('externalTradeFair.tax') + '(' + $n(getTaxPrc(detailsData.app_main.year))  + '%)' }}</b-td>
                            <b-td class="text-right" colspan="2" v-if="detailsData.app_main.circular_type == 2">{{ $t('externalTradeFair.tax') + '(' + $n(getTaxPrc(detailsData.app_main.year))  + '%)' }}</b-td>
                            <b-td class="text-right">{{ $n(getTaxAmount(detailsData.quoted_price), { minimumFractionDigits: 2 }) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right" colspan="3" v-if="detailsData.app_main.circular_type == 1">{{ $t('sitePreference.grandTotal') + ' ' + $t('externalTradeFair.payable') }}</b-td>
                            <b-td class="text-right" colspan="2" v-if="detailsData.app_main.circular_type == 2">{{ $t('sitePreference.grandTotal') + ' ' + $t('externalTradeFair.payable') }}</b-td>
                            <b-td class="bg-warning text-right">{{ $n(paymentData.payment_amount, { minimumFractionDigits: 2 }) }}</b-td>
                        </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-row>
            </b-overlay>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { DitfAppStallPaymentReceipt } from '../../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import moment from 'moment'
export default {
  name: 'Form',
  props: ['id', 'appId'],
  components: {
    ListReportHead
  },
  data () {
    return {
        baseUrl: internationalTradeFairServiceBaseUrl,
        comp: 'PaymentOnline',
        loader: false,
        detailsData: {},
        stallCategoryList: [],
        stalls: [],
        paymentData: {},
        category_info: {},
        bankIds: {}
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getEditData()
      this.detailsData = tmp
      this.getPayInfo()
      // category info
      this.category_info = this.getStallCategoryName(this.detailsData.stall_cat_id)
      this.bankIds = this.getFairBankId()
    }
  },
  watch: {
//
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getSecurityPrc (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(n))
        if (typeof Obj !== 'undefined') {
          const percentage = Obj.percentage
          return percentage
        } else {
        return 0
        }
    },
    dateData (fdate) {
      const date = moment(fdate).format('MM/DD/YYYY')
      // const date = moment(fdate).format('Do MMMM, YYYY')
      return date
    },
    getVatPrc (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(n))
        if (typeof Obj !== 'undefined') {
          const vat = Obj.vat
          return vat
        } else {
        return 0
        }
    },
    getTaxPrc (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(n))
        if (typeof Obj !== 'undefined') {
          const tax = Obj.tax
          return tax
        } else {
        return 0
        }
    },
    getTaxAmount (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(this.detailsData.app_main.year))
        if (typeof Obj !== 'undefined') {
          const tax = Obj.tax
          const taxAmount = (n * tax) / 100
          const taxMoney = taxAmount
          return taxMoney
        } else {
        return 0
        }
    },
    getVatAmount (n) {
      const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === parseInt(this.detailsData.app_main.year))
        if (typeof Obj !== 'undefined') {
          const vat = Obj.vat
          const vatAmount = (n * vat) / 100
          const vatMoney = vatAmount
          return vatMoney
        } else {
        return 0
        }
    },
    async getPayInfo () {
        this.loader = true
        const myObj = {
            app_main_id: this.appId,
            stall_cat_id: this.detailsData.stall_cat_id,
            stall_id: this.detailsData.stall_id,
            circular_type: parseInt(this.detailsData.app_main.circular_type)
        }
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, DitfAppStallPaymentReceipt, myObj)
        this.loader = false
        if (result.success) {
            this.paymentData = result.data
        }
    },
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
      return JSON.parse(JSON.stringify(tmpData))
    },
    getStallCategoryName (id) {
        return this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
    },
    getBankName (id) {
        const dataBankData = this.$store.state.CommonService.commonObj.bankList.find(item => item.value === parseInt(id))
        if (typeof dataBankData !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataBankData.text_en : dataBankData.text_bn
        } else {
          return ''
        }
    },
    getBranchName (id) {
        const dataBranchData = this.$store.state.CommonService.commonObj.branchList.find(item => item.value === parseInt(id))
        if (typeof dataBranchData !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataBranchData.text_en : dataBranchData.text_bn
        } else {
          return ''
        }
    },
    getFairName (id) {
        const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.dhakaFairList.find(item => parseInt(item.value) === parseInt(id))
        if (this.currentLocale === 'bn') {
          return Obj !== undefined ? Obj.text_bn : ''
        } else {
          return Obj !== undefined ? Obj.text_en : ''
        }
    },
    async pdfExport () {
      this.loader = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 2, app_main_id: this.appId, pay_id: this.id, stall_id: this.detailsData.stall_id, stall_cat_id: this.detailsData.stall_cat_id, circular_type: parseInt(this.detailsData.app_main.circular_type) })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, DitfAppStallPaymentReceipt, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.loader = false
    },
    async getFairBankId () {
      const bankList = this.$store.state.CommonService.commonObj.bankList.filter(item => item.status === 1)
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, '/ditf-configuration/bank-info/details', null)
      if (result.success) {
        this.sortedBankList = bankList.filter(item => result.data.includes(item.value))
      } else {
        this.sortedBankList = []
      }
    }
  }
}
</script>
